<template>
    <div class="list-view">
        <page-title>
            <el-button @click="addTimeRow" type="primary" icon="el-icon-plus" :disabled="fetchingData || downloading"
                class="filter-item">
                指定时间
            </el-button>
            <el-button @click="addRow" type="primary" icon="el-icon-plus" :disabled="fetchingData || downloading"
                class="filter-item">
                批量
            </el-button>

            <el-button @click="editRow" type="primary" icon="el-icon-plus" :disabled="fetchingData || downloading"
                class="filter-item">
                新增
            </el-button>
            <!--    <el-button @click="addAllRow"-->
            <!--               type="primary"-->
            <!--               icon="el-icon-plus"-->
            <!--               :disabled="fetchingData || downloading"-->
            <!--               class="filter-item">-->
            <!--        导入-->
            <!--    </el-button>-->
            <!--         action 是上传地址  limit是上传数量-->
            <el-upload :action="uploadUrl" :on-progress="handleProgress" :auto-upload="true" :show-file-list="false"
                :headers="headers">
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
<!--            <el-upload :action="hcUrl" :on-progress="handleProgress" :auto-upload="true" :show-file-list="false"
                :headers="headers">
                <el-button size="small" type="primary">合成上传</el-button>
            </el-upload> -->
            <!-- <el-button @click="download" icon="el-icon-upload2" :loading="downloading" :disabled="fetchingData" class="filter-item">
                导出
            </el-button> -->
        </page-title>
        <div class="filters-container">
            <created-at-picker v-model="createdAt" name="创建" @input="initData"></created-at-picker>
            <el-input placeholder="搜索..." v-model="search" clearable class="filter-item search"
                @keyup.enter.native="initData">
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table :data="tableData" row-key="id" ref="table" header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell" row-class-name="table-row" cell-class-name="table-cell"
            :height="tableHeight" v-loading="fetchingData">
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="name" label="空投名称"> </el-table-column>
            <el-table-column prop="type" label="空投类型" :formatter="typeFormatter"> </el-table-column>
            <el-table-column prop="phone" label="手机号" show-overflow-tooltip>
                <template v-slot="{ row }">{{ row.phone.join() }}</template>
            </el-table-column>
            <el-table-column prop="conponOrCollection" label="内容ID">
                <template slot-scope="scope">
                    <span>{{ scope.row.couponId }}</span>
                    <span>{{ scope.row.collectionId }}</span>
                    <span>{{ scope.row.surpriseBoxId }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="dropCount" label="投放数量"></el-table-column>
            <el-table-column prop="dropPrice" label="投放价格"></el-table-column>
            <el-table-column prop="operation" label="投放内容"></el-table-column>
            <el-table-column prop="createdAt" label="创建时间"></el-table-column>
            <!-- <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <div class="pagination-wrapper">
            <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
            <el-pagination background @size-change="onSizeChange" @current-change="onCurrentChange" :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalElements">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import resolveUrl from 'resolve-url';
import BaseUrl from '../utils/baseUrl';

export default {
    name: 'AirDropList',
    mixins: [pageableTable],
    data() {
        return {
            fileList: [],
            headers: {
                Authorization: 'Bearer ' + window.localStorage.getItem('token')
            },
            multipleMode: false,
            search: '',
            url: '/airDrop/all',
            downloading: false,
            typeOptions: [
                { label: '藏品', value: 'asset' },
                { label: '惊喜盒子', value: 'SURPRISE_BOX' },
                { label: '兑换券', value: 'coupon' }
            ],
            createdAt: '',
            uploadUrl: ''
            //hcUrl: ''

        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    created() {
        this.uploadUrl = resolveUrl(BaseUrl, 'nft/airDrop/importBatch');
        //this.hcUrl = resolveUrl(BaseUrl, 'nft/airDrop/importBatchKT');
    },
    methods: {
        typeFormatter(row, column, cellValue, index) {
            let selectedOption = this.typeOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        beforeGetData() {
            return { search: this.search, query: { del: false, createdAt: this.createdAt } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/airDropAdd',
                query: {
                    ...this.$route.query
                }
            });
        },
        addTimeRow() {
            this.$router.push({
                path: '/airDropTime',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow() {
            this.$router.push({
                path: '/airDropEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        handleProgress(files) {
            console.log(files)
        },
        // editRow(row) {
        //     this.$router.push({
        //         path: '/airDropEdit',
        //         query: {
        //             id: row.id
        //         }
        //     });
        // },
        download() {
            this.downloading = true;
            this.$axios
                .get('/airDrop/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/airDrop/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
